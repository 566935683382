<template>
    <div :class="{ prices: true, 'no-grid': netPrice }">
        <div>
            <div
                v-if="showNoEKPrices && product.purchasePrice"
                :class="{ 'prices__price-container': viewType && !netPrice }"
                data-testid="productPurchasePrice"
            >
                <span class="prices__price-type">
                    <template v-if="isKernProduct && !hasFromSuffix">
                        <span data-testid="priceKernPrice">{{ $t('Product.kern_price') }}</span>
                    </template>
                    <template v-else-if="isKernProduct && (hasFromSuffix || product.purchasePriceFromLabel)">
                        <span data-testid="priceKernPrice">
                            {{ $t('Product.kern_price') }} {{ $t('Product.from') }}
                        </span>
                    </template>
                    <template v-else>
                        {{ hasNetPrice ? $t('Cart.net_ek').toUpperCase() : $t('Product.ek_price') }}
                        {{ (netPrice && hasFromSuffix) || product.purchasePriceFromLabel ? $t('Product.from') : '' }}
                    </template>
                </span>
                <span
                    :class="{
                        prices__price: true,
                        prices__tile: viewType === 'tile',
                    }"
                >
                    {{
                        useMoneyFormat(
                            isIntersportKernPriceType && product.purchasePriceOriginal
                                ? product.purchasePriceOriginal
                                : product.purchasePrice
                        )
                    }}
                </span>
            </div>
        </div>

        <div>
            <div
                v-if="product.sellPrice"
                :class="{ 'prices__price-container': viewType && !netPrice }"
                data-testid="productSellPrice"
            >
                <span class="prices__price-type">
                    {{ product.sellPriceOriginal ? $t('Product.upa_price') : $t('Product.upe_price') }}
                    {{ (netPrice && hasFromSuffix) || product.sellPriceFromLabel ? $t('Product.from') : '' }}
                </span>
                <span class="prices__price" :class="{ prices__tile: viewType === 'tile' }">
                    {{ useMoneyFormat(product.sellPrice) }}
                </span>
            </div>
        </div>

        <div>
            <div v-if="showNoEKPrices && product.purchasePriceOriginal && !isIntersportKernPriceType">
                <div
                    :class="{
                        'prices__price-container': viewType && !netPrice,
                        'prices__price-container-original': viewType,
                    }"
                >
                    <span class="prices__price-type" :class="{ 'prices__price-original': viewType !== 'tile' }">
                        {{ $t('Product.ek_price') }}
                        {{
                            (netPrice && hasFromSuffix) || product.purchasePriceOriginalFromLabel
                                ? $t('Product.from')
                                : ''
                        }}
                    </span>
                    <span
                        class="prices__price prices__price-original line-through"
                        :class="{ prices__tile: viewType === 'tile' }"
                    >
                        {{ useMoneyFormat(product.purchasePriceOriginal) }}
                    </span>
                </div>
            </div>
        </div>

        <div>
            <div v-if="product.sellPriceOriginal">
                <div
                    :class="{
                        'prices__price-container': viewType && !netPrice,
                        'prices__price-container-original': viewType,
                    }"
                    data-testid="productSellPriceOriginal"
                >
                    <span class="prices__price-type" :class="{ 'prices__price-original': viewType !== 'tile' }">
                        {{ $t('Product.upe_price') }}
                        {{
                            (netPrice && hasFromSuffix) || product.sellPriceOriginalFromLabel ? $t('Product.from') : ''
                        }}
                    </span>
                    <span
                        class="prices__price prices__price-original line-through"
                        :class="{ prices__tile: viewType === 'tile' }"
                    >
                        {{ useMoneyFormat(product.sellPriceOriginal) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { ProductPrice } from '~/composables/types/api/searchDiscover/catalogSearch'
import type { ProductPrice as SuggestionsProductPrice } from '~/composables/types/api/searchDiscover/catalogSearchSuggestions'
import type { NetProductPrices } from '~/composables/types/api/cartConditions/netPrices'
import useMoneyFormat from '~/composables/utils/useMoneyFormat'
import { usePreferencesStore } from '~/composables/stores/usePreferencesStore'

const props = withDefaults(
    defineProps<{
        product: ProductPrice | SuggestionsProductPrice | NetProductPrices
        viewType?: string | null
        hasFromSuffix?: boolean
        hasNetPrice?: boolean
    }>(),
    {
        viewType: null,
        hasFromSuffix: false,
        hasNetPrice: false,
    }
)

const preferencesStore = usePreferencesStore()
const showNoEKPrices = computed(() => preferencesStore.showNoEKPrices)
const intersportKernFkPriceType = 11
const isLabelActive = ref({
    kern: props.product?.labels?.includes('KERN'),
    fk: props.product?.labels?.includes('FK'),
})

const netPrice = computed(() => props.viewType === 'netPrice')

const isIntersportKernPriceType = computed(
    () => props.product.purchasePriceIntersportType === intersportKernFkPriceType
)

const isKernProduct = computed(
    () => isIntersportKernPriceType.value && (isLabelActive.value.kern || isLabelActive.value.fk)
)

watch(
    () => props.product.labels,
    () => {
        isLabelActive.value = {
            kern: props.product?.labels?.includes('KERN'),
            fk: props.product?.labels?.includes('FK'),
        }
    }
)
</script>

<style lang="scss" scoped>
.prices {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: sp(xs);
    white-space: nowrap;

    &__price-container {
        display: flex;
        flex-direction: column;
        line-height: 1;

        @include helper-color(text-secondary);
    }

    &__price-type {
        @include helper-font-size(smaller);
        @include helper-font-weight(medium);
    }

    &__price {
        margin-left: sp(xxs);

        @include helper-font-size(big);
        @include helper-font-weight(bold);
        @include helper-color(text-title);
    }

    &__tile {
        margin-left: 0;
    }

    &__price-original {
        @include helper-color(alert-danger);
    }

    .line-through {
        text-decoration: line-through;

        @include helper-font-weight(medium);
        @include helper-font-size(default);
    }

    .hidden {
        visibility: hidden;
    }
}

.no-grid {
    display: inline;
}
</style>
